import * as spaceActions from "./actions/space-actions.js"
import * as collaboratorActions from "./actions/collaborator-actions.js"
import * as projectActions from "./actions/project-actions.js"
import * as peerRegistry from "./peer-registry.js"
export {
    DataChannelMessages,
} from "./data-channel-handler.js"
export { actionEmitter, ActionTypes } from "./actions/index.js"
export { setVM } from "./vm.js"
export { clusterDetails, ClusterState, ClusterSpaceInfo } from "./cluster.js"
export {
    ensureSocketConnected,
    concludeSocket,
    sendWSMessage,
} from "./socket-client.js"

export {
    ConnectionQuality,
    ConnectionStatus,
    connectivityState,
    setWebsocketConnectionQuality,
    setOutgoingMediaConnectionQuality,
    setIncomingMediaConnectionQuality,
    setWebsocketConnectionStatus,
    setOutgoingMediaConnectionStatus,
    setIncomingMediaConnectionStatus,
    mediaFailurePopupDismissed,
} from './connectivity-status.js';

// @ts-ignore
export {
    currentPeerId as currentCollaboratorId,
    markSpaceLocked,
} from "./peer-registry.js"
export {
    copyBlocksToLocalProject,
    copySpriteToLocalProject,
    setCanvas,
    startMicOutput,
    stopMicOutput,
    localAudioTrack,
    localCanvasStreamTrack,
    broadcastUserUpdate,
    initialIdDeferred,
    initialProjectDetailsDeferred,
    notifyCopyBlocksToLocalProject,
    peerConnectivityStatus,
    disableBrokenConnectionHandling,
    toggleWebcam,
} from "./rtc-handlers.js"
export {
    emitProject,
    emitProjectImmediately,
    setHoveredProject,
    setLiveStatus,
    handleViewProject,
    handleCancelViewProject,
    captureSnapshot,
    captureSnapshotOrIgnore,
    getLatestSnapshot,
    visitedCollaboratorId,
    collaboratorIdToSnapshotMap,
    getProjectData,
    replaceWithPeerProject,
    isProjectLoading,
    trackLocalSnapshot,
    currentProjectPayload,
    isVisitingSelf,
    projectDataMap,
    remixComplete,
    forceSysAdminFullProjectSave,
} from "./project-data-coordinator.js"
export {
    getScreenShareStream,
    checkSupported,
    cleanup,
    pauseRecording,
    recorderState,
    resumeRecording,
    startRecording,
    stopRecording,
    resetRecorder,
    disableMic,
    startMic,
} from "./screen-capture.js"
export { emitReaction } from "./reaction-dispatcher.js"
import { httpClient, getServerBaseUrl, getPublicAssetBucketUrl } from "./http-client.js"
export { spaceVars, spaceVarsEmitter } from "./space-vars.js"
export { atom } from "./utils/store.js"
export { Deferred, Thunk, timeout } from "./utils/promise.js"
export {
    tidyCode,
    codeEditorStore,
    getCodeArchive,
    loadCodeArchive,
} from "./p5/state.js"
export { getCodePreviewStore } from "./p5/preview.js"
export { spaceDetails } from "./space.js"
export * from "./canvas-data-coordinator.js"
export {
    getFlagsmith,
    setFlagsmith,
} from "./flagsmith.js";

export {
    peerRegistry,
    collaboratorActions,
    spaceActions,
    projectActions,
    httpClient,
    getServerBaseUrl,
    getPublicAssetBucketUrl,
}

if (typeof window !== "undefined") {
    // Primary for debugging
    ;(window as any).__debug__peerRegistry = peerRegistry
    ;(window as any).__debug__httpClient = httpClient
}
